export enum FieldName {
  Id = 'id',
  Name = 'name',
  MetricType = 'metricType',
  FriendlyName = 'friendlyName',
  Category = 'category',
  Description = 'description',
  Note = 'note',
  IncludePeriods = 'includePeriods',
  DefinitionData = 'definitionData',
}

export enum GroupName {
  Details = 'details',
  Aggregations = 'aggregations',
  Periods = 'periods',
  Definition = 'definition',
}
