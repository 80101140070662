<form *ngIf="form && formInputs" class="bo-metric-form" [formGroup]="form">
  <div class="u-margin-bottom--xl" *ngIf="!isNewFormMode(mode)">
    <app-heading
      extraClasses="m-heading--lg u-margin-bottom--md"
      titleText="MTR_COMMON_NAVIGATION"
    ></app-heading>
    <div class="u-separator u-margin-bottom--xl"></div>
    <div class="bo-metric-form__navigation-header">
      <app-heading
        subExtraClasses="m-subheading--md u-margin-bottom--xl m-bold"
        subtitleText="MTR_COMMON_DEPENDENCIES"
      >
      </app-heading>
      <app-checkbox-group
        [config]="navigationCheckBoxGroup"
        [disabled]="form.disabled"
        [ngModel]="navigationHeaderCheckBoxData"
        [ngModelOptions]="{ standalone: true }"
        (onChange)="onCheckboxGroupChange($event)"
      ></app-checkbox-group>
    </div>
    <div class="bo-metric-form__dependencies u-margin-bottom--xl">
      <app-tree
        [value]="dependenciesTree"
        [selection]="data?.selectedDependencies"
        selectionMode="single"
        [expandedOnStartup]="true"
        (nodeSelect)="onDependencySelect($event)"
      ></app-tree>
    </div>
    <app-heading
      subExtraClasses="m-subheading--md u-margin-bottom--xl m-bold"
      subtitleText="MTR_COMMON_DEPENDENCIES"
    >
    </app-heading>
    <div class="bo-metric-form__supported u-margin-bottom--xl">
      <app-tree
        [value]="supportedTree"
        [selection]="data?.selectedSupported"
        selectionMode="single"
        [expandedOnStartup]="true"
        (nodeSelect)="onSupportedSelect($event)"
      ></app-tree>
    </div>
  </div>

  <div class="u-margin-bottom--xl">
    <app-heading
      extraClasses="m-heading--lg u-margin-bottom--md"
      titleText="MTR_COMMON_DETAILS"
    ></app-heading>
    <div class="u-separator u-margin-bottom--xl"></div>
    <div [formGroupName]="GroupName.Details">
      <div class="u-margin-bottom--md p-grid">
        <div class="p-col-12 p-md-6">
          <div class="p-grid p-align-center">
            <ng-container
              *ngTemplateOutlet="
                formInput;
                context: { inputKey: FieldName.Name, col: { label: 4, input: 8 } }
              "
            ></ng-container>
            <ng-container
              *ngTemplateOutlet="
                formInput;
                context: { inputKey: FieldName.FriendlyName, col: { label: 4, input: 8 } }
              "
            ></ng-container>
          </div>
        </div>
        <div class="p-col-12 p-md-6">
          <div class="p-grid p-align-center">
            <ng-container
              *ngTemplateOutlet="
                formInput;
                context: { inputKey: FieldName.MetricType, col: { label: 4, input: 8 } }
              "
            ></ng-container>
            <ng-container
              *ngTemplateOutlet="
                formInput;
                context: { inputKey: FieldName.Category, col: { label: 4, input: 8 } }
              "
            ></ng-container>
          </div>
        </div>
      </div>
      <ng-template #formInput let-inputKey="inputKey" let-col="col">
        <ng-container
          [ngSwitch]="formInputs[inputKey].type"
          *ngIf="
            formInputs[inputKey] &&
            (!formInputs[inputKey].hideFn ||
              (formInputs[inputKey].hideFn && !formInputs[inputKey].hideFn(mode)))
          "
        >
          <div class="p-col-6 m-body__accent" [ngClass]="'p-lg-' + col.label">
            <span
              class="m-body__accent"
              [class.bo-form__input--required]="
                isEdit && formInputs[inputKey].validators?.includes(VALIDATORS.required)
              "
              >{{ formInputs[inputKey].label | translate }}</span
            >
          </div>
          <div class="p-col-6 m-field__container" [ngClass]="'p-lg-' + col.input">
            <input
              pInputText
              class="bo-form__input"
              [formControlName]="inputKey"
              *ngSwitchCase="InputType.Text"
            />
            <textarea
              pInputText
              class="bo-form__input"
              [formControlName]="inputKey"
              *ngSwitchCase="InputType.Textarea"
            ></textarea>
            <mc-select
              *ngSwitchCase="InputType.Dropdown"
              class="bo-form__input"
              [options]="formInputs[inputKey].options | async"
              [placeholder]="formInputs[inputKey].placeholder"
              [formControlName]="inputKey"
            ></mc-select>
            <!--        <span class="bo-form__input-error"
                          *ngIf="form.get(inputKey).hasError('required') && formInputs[inputKey].validators.includes(VALIDATORS.required)">
                        {{'MTR_COMMON_ERROR_FIELD_REQUIRED' | translate}}
                    </span>-->
          </div>
        </ng-container>
      </ng-template>
    </div>
  </div>

  <div class="u-margin-bottom--xl">
    <app-heading
      extraClasses="m-heading--lg u-margin-bottom--md"
      titleText="MTR_COMMON_AGGREGATIONS"
    ></app-heading>
    <div class="u-separator u-margin-bottom--xl"></div>
  </div>

  <div class="u-margin-bottom--xl">
    <app-heading
      extraClasses="m-heading--lg u-margin-bottom--md"
      titleText="MTR_COMMON_PERIODS"
    ></app-heading>
    <div class="u-separator u-margin-bottom--xl"></div>
    <div class="u-margin-bottom--md p-grid">
      <div class="p-col-12" [formGroupName]="GroupName.Periods">
        <app-checkbox
          [formControlName]="FieldName.IncludePeriods"
          [binary]="'true'"
          [label]="'MTR_COMMON_INCLUDE_PERIODS'"
        ></app-checkbox>
      </div>
    </div>
  </div>

  <div class="u-margin-bottom--xl">
    <app-heading
      extraClasses="m-heading--lg u-margin-bottom--md"
      titleText="MTR_COMMON_DEFINITION"
    ></app-heading>
    <div class="u-separator u-margin-bottom--xl"></div>
    <div class="u-margin-bottom--md p-grid">
      <div class="p-col-12" [formGroupName]="GroupName.Definition">
        <textarea
          pInputText
          class="bo-form__input"
          [formControlName]="FieldName.DefinitionData"
        ></textarea>
      </div>
    </div>
  </div>
</form>
