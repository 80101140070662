import { Injectable } from '@angular/core'
import { HttpClient, HttpContext } from '@angular/common/http'

import { Observable, of, throwError } from 'rxjs'
import { catchError, map, share, switchMap, tap } from 'rxjs/operators'

import { environment } from '@env'
import { LazyParams } from '@shared/components/table/table.models'
import { FilterOption } from '@shared/components/filters/filters.models'
import { SortingService } from '@core/services/sorting/sorting.service'
import { MatchQualifier } from '@core/enums/matches.enums'
import { QUALITY_TYPE_TRANSLATION } from '@core/constants/quality.constants'
import {
  ASSET_STATUS_TRANSLATION,
  ASSET_TYPE_TRANSLATION,
  CONTENT_TYPE_TRANSLATION,
} from '@core/constants/asset.constants'

import {
  INCIDENCE_CAMERA_TYPOLOGY_TRANSLATION,
  INCIDENCE_RESOLUTION_TYPE_TRANSLATION,
  INCIDENCE_TYPE_TRANSLATION,
} from '@core/constants/incidence.constants'

import { DataType } from './matches.enums'
import { FormMode } from '../../enums/form-mode.enums'
import { createTranslationKeys, inRange } from '../../utils/main'
import {
  DataOptions,
  MatchPublishing,
  MatchQualifierInfo,
  MatchValidate,
  Season,
} from './matches.models'
import { MATCH_STATE_TRANSLATION, PROVIDER_DEFAULT_NAME } from './matches.constants'
import { PrivateAssetType } from '@core/enums/asset.enums'
import {
  IncidenceCameraTypology,
  IncidenceResolutionType,
  IncidenceType,
} from '@core/enums/incidence.enums'
import { logError } from '@shared/opretators/log-error.operator'

@Injectable({
  providedIn: 'root',
})
export class MatchesService {
  dataCache: any = {}
  data$: { [key: string]: Observable<any> } | {} = {}

  constructor(
    private http: HttpClient,
    private sortingService: SortingService,
  ) {}

  getMatches$(url: string, params = {}, lazyParams: LazyParams = <LazyParams>{}) {
    const paramsBackEnd = {
      filtering: {
        freeText: '',
        filterQuality: 'full',
        ...params,
      },
      ...lazyParams,
    }

    const QUALIFIER_FALLBACK_TRANSLATION = (
      matchQualifier: MatchQualifier,
      isAvailable: boolean,
    ) =>
      isAvailable === false
        ? QUALITY_TYPE_TRANSLATION[matchQualifier]
        : 'MTR_MATCHES_QUALIFIER_WAITING'

    return this.http.post<any>(url, paramsBackEnd).pipe(
      map((data) => ({
        ...data,
        sorting: {
          ...data.sorting,
          ...lazyParams.sorting,
        },
        matches: this.sortingService.sort(
          data.matches.map((match: any) => ({
            ...match,
            providerName: match.providerName || PROVIDER_DEFAULT_NAME,
            teams: match.homeTeam + ' - ' + match.awayTeam,
            ...createTranslationKeys(
              QUALITY_TYPE_TRANSLATION,
              match,
              ['eventingQuality', 'trackingQuality'],
              [
                QUALIFIER_FALLBACK_TRANSLATION(MatchQualifier.Eventing, match.isEventingAvailable),
                QUALIFIER_FALLBACK_TRANSLATION(MatchQualifier.Tracking, match.isTrackingAvailable),
              ],
            ),
            ...createTranslationKeys(MATCH_STATE_TRANSLATION, match, ['state']),
          })),
        ),
      })),
      share(),
      logError(),
    )
  }

  getMatchesPublishing$(params = {}, lazyParams: LazyParams = <LazyParams>{}) {
    return this.getMatches$(environment.API.SEARCH_MATCHES, params, lazyParams)
  }

  getMatchesValidateIntegration$(params = {}, lazyParams: LazyParams = <LazyParams>{}) {
    return this.getMatches$(
      environment.API.SEARCH_MATCHES,
      { ...params, filterQuality: 'withAlignments' },
      lazyParams,
    )
  }

  getMatchesPublishingById(matchId: string, isLastVersion = true, context?: HttpContext) {
    return this.http
      .get<any>(environment.API.MATCHES, {
        params: { matchId, lastVersion: isLastVersion },
        context,
      })
      .pipe(
        map((match: MatchPublishing) => ({
          ...match,
          assets: (match.assets || []).map((asset) => ({
            ...asset,
            ...createTranslationKeys(QUALITY_TYPE_TRANSLATION, asset, ['dataQuality']),
            ...createTranslationKeys(ASSET_TYPE_TRANSLATION, asset, ['assetType']),
            ...createTranslationKeys(ASSET_STATUS_TRANSLATION, asset, ['status']),
            ...createTranslationKeys(CONTENT_TYPE_TRANSLATION, asset, ['contentType']),
          })),
        })),
        share(),
        logError(),
      )
  }

  getMatchPublishingById(id: string, isLastVersion = true, context?: HttpContext) {
    return this.getMatchesPublishingById(id, isLastVersion, context)
  }

  getData$(dataType: DataType, apiUrl: string, forceFetch?: boolean) {
    if (this.dataCache[dataType] && !forceFetch) {
      return of(this.dataCache[dataType])
    } else {
      return !forceFetch && (this.data$ as any)[dataType]
        ? (this.data$ as any)[dataType]
        : ((this.data$ as any)[dataType] = this.http.get<any>(apiUrl).pipe(
            map((data) => data[dataType]),
            tap((data) => (this.dataCache[dataType] = data)),
            share(),
            catchError((error) => {
              ;(this.data$ as any)[dataType] = null
              return throwError(() => error)
            }),
          ))
    }
  }

  getDataWithParams$(
    dataType: DataType,
    apiUrl: string,
    options: DataOptions,
    dataWrapper = dataType,
    forceFetch?: boolean,
  ) {
    const { params, searchParams } = options || <DataOptions>{}
    const paramHash = JSON.stringify({ ...params, ...searchParams })
    const hasCache = (this.dataCache[dataType] || {})[paramHash]
    if (hasCache && !forceFetch) {
      return of(hasCache)
    } else {
      ;(this.data$ as any)[dataType] = (this.data$ as any)[dataType] || {}
      this.dataCache[dataType] = this.dataCache[dataType] || {}
      return !forceFetch && (this.data$ as any)[dataType][paramHash]
        ? (this.data$ as any)[dataType][paramHash]
        : ((this.data$ as any)[dataType][paramHash] = this.http
            .get<any>(apiUrl, { params: searchParams })
            .pipe(
              map((data) => data[dataWrapper]),
              tap((data) => {
                this.dataCache[dataType] = {
                  ...(this.dataCache[dataType] || {}),
                  [paramHash]: data,
                }
              }),
              share(),
              catchError((error) => {
                ;(this.data$ as any)[dataType][paramHash] = null
                return throwError(() => error)
              }),
            ))
    }
  }

  getSeasons$(forceFetch?: boolean) {
    return this.getData$(DataType.Season, environment.API.SEASONS, forceFetch)
  }

  getSeasonOptions$(forceFetch?: boolean) {
    return this.getSeasons$(forceFetch).pipe(
      map((data: Season[]) =>
        data.map(({ id, officialName, isCurrentSeason }) => ({
          label: officialName,
          value: id,
          ...(isCurrentSeason ? { isDefaultValue: isCurrentSeason } : {}),
        })),
      ),
    )
  }

  getCurrentSeasonName() {
    return this.getSeasons$().pipe(
      map(
        (seasons: Season[]) =>
          (seasons.find((season) => season.isCurrentSeason) || <Season>{}).officialName,
      ),
    )
  }

  getCompetitions$() {
    return this.getData$(DataType.Competition, environment.API.COMPETITIONS)
  }

  getCompetitionOptions$() {
    return this.getCompetitions$().pipe(
      map((data: { id: string; officialName: string }[]) =>
        data.map(({ id, officialName }) => ({
          label: officialName,
          value: id,
        })),
      ),
    )
  }

  getMatchdays$(params: any, forceFetch?: boolean) {
    return this.getDataWithParams$(
      DataType.Matchdays,
      environment.API.MATCH_DAYS,
      { searchParams: params },
      DataType.Matchdays,
      forceFetch,
    )
  }

  getMatchDayOptions$(params: { SeasonId: string; CompetitionId: string }, forceFetch?: boolean) {
    return this.getMatchdays$(params, forceFetch)
      .pipe(
        map((data: { number: number }[]) =>
          data.map(({ number }) => ({ label: number + '', value: number })),
        ),
      )
      .pipe(map((data: FilterOption[]) => this.sortingService.sort(data, 'value').reverse()))
  }

  getMatchDayOptionsByCurrent$(
    params: {
      SeasonId: string
      CompetitionId: string
      matchDayOffset?: number
      autoSelectIndex?: number
    },
    forceFetch?: boolean,
  ) {
    interface Competition {
      id: string
      currentMatchDayNumber: number
    }

    const { SeasonId, CompetitionId, matchDayOffset, autoSelectIndex } = params
    return this.getCompetitionsBySeason$(SeasonId, forceFetch).pipe(
      map(
        (data: Competition[]) =>
          (data.find(({ id }) => id === CompetitionId) || <Competition>{}).currentMatchDayNumber,
      ),
      switchMap((matchDayNumber: number) =>
        this.getMatchdays$({ SeasonId, CompetitionId }, forceFetch).pipe(
          map((data: { number: number }[]) =>
            inRange(
              matchDayNumber + (matchDayOffset || 0),
              Math.max(...data.map(({ number }) => number)),
            ),
          ),
        ),
      ),
      map((matchDayNumber) =>
        Array(matchDayNumber)
          .fill(1)
          .map((v, i) => i + 1)
          .map((number) => ({
            label: number + '',
            value: number,
          })),
      ),
      map((data: FilterOption[]) => this.sortingService.sort(data, 'value').reverse()),
      map((data: FilterOption[]) =>
        data.map((option, index) => ({
          ...option,
          ...(index === autoSelectIndex ? { isDefaultValue: true } : {}),
        })),
      ),
    )
  }

  getCompetitionsBySeason$(seasonId: string, forceFetch?: boolean) {
    const apiUrl = `${environment.API.COMPETITIONS}/season/${seasonId}`
    return this.getDataWithParams$(
      DataType.CompetitionById,
      apiUrl,
      { params: { seasonId } },
      DataType.Competition,
      forceFetch,
    )
  }

  getCompetitionsBySeasonOptions$(
    seasonId: string,
    autoSelect = false,
    whiteList?: string[],
    forceFetch?: boolean,
  ) {
    return this.getCompetitionsBySeason$(seasonId, forceFetch).pipe(
      map((data: { id: string; officialName: string; currentMatchDayNumber: number }[]) =>
        data
          .filter(({ id }) => !whiteList || whiteList.includes(id))
          .map(({ id, officialName, currentMatchDayNumber }, index) => ({
            label: officialName,
            value: id,
            currentMatchDayNumber,
            ...(autoSelect && index === 0 ? { isDefaultValue: true } : {}),
          })),
      ),
    )
  }

  generatePrivateMedia(ids: string[], assetTypes: PrivateAssetType[]) {
    const paramsBackEnd = {
      assetTypes,
      matchIds: ids,
    }
    return this.http.post<any>(environment.API.PRIVATE_MEDIA, paramsBackEnd)
  }

  updateMatchValidate$(match: MatchValidate) {
    const { id: matchId, isFootballMatchValidated } = match
    return this.http.put<any>(environment.API.MATCH_VALIDATE, { matchId, isFootballMatchValidated })
  }

  setMatchQualifier$(qualifierInfo: MatchQualifierInfo) {
    const { type, isAvailable, quality, matchIds } = qualifierInfo
    return this.http.put<any>(environment.API.MATCH_QUALIFIERS, {
      type,
      isAvailable,
      ...(quality && { quality }),
      matchIds,
    })
  }

  getDataList$<T>(apiUrl: string, params?: any) {
    return this.http.get<T>(apiUrl, { params })
  }

  getIncidenceTypesOptions$() {
    return this.getDataList$<
      { id: string; description: string; defaultMessageESes: string; defaultMessageENgb: string }[]
    >(environment.API.INCIDENCE_TYPES).pipe(
      map((data) =>
        data.map(({ id, description, defaultMessageENgb, defaultMessageESes }) => ({
          key: description,
          label: INCIDENCE_TYPE_TRANSLATION[description as IncidenceType],
          value: id,
          en: defaultMessageENgb,
          es: defaultMessageESes,
        })),
      ),
    )
  }

  getIncidenceResolutionTypesOptions$() {
    return this.getDataList$<{ id: string; description: string }[]>(
      environment.API.INCIDENCE_RESOLUTION_TYPES,
    ).pipe(
      map((data) =>
        data.map(({ id, description }) => ({
          key: description,
          label: INCIDENCE_RESOLUTION_TYPE_TRANSLATION[description as IncidenceResolutionType],
          value: id,
        })),
      ),
    )
  }

  getIncidenceCameraTypologiesOptions$() {
    return this.getDataList$<{ id: string; description: string }[]>(
      environment.API.INCIDENCE_CAMERA_TYPOLOGIES,
    ).pipe(
      map((data) =>
        data.map(({ id, description }) => ({
          key: description,
          label: INCIDENCE_CAMERA_TYPOLOGY_TRANSLATION[description as IncidenceCameraTypology],
          value: {
            id,
            key: INCIDENCE_CAMERA_TYPOLOGY_TRANSLATION[description as IncidenceCameraTypology],
          },
        })),
      ),
    )
  }

  getIncidenceApplicationsOptions$() {
    return this.getDataList$<{ id: string; name: string }[]>(
      environment.API.INCIDENCE_APPLICATIONS,
    ).pipe(
      map((data) =>
        data.map(({ id, name }) => ({
          key: name,
          label: name,
          value: id,
        })),
      ),
    )
  }

  getIncidenceById$(id: string) {
    return this.http.get<any>(`${environment.API.CRUD_INCIDENCE}/${id}`).pipe(share(), logError())
  }

  crudIncidence$(incidence: any, type = FormMode.Edit) {
    const isEdit = [FormMode.Edit, FormMode.Resolve].includes(type)
    const id = isEdit ? `/${incidence.incidenceId}` : ''
    return this.http[isEdit ? 'put' : 'post'](`${environment.API.CRUD_INCIDENCE}${id}`, incidence)
  }
}
