import { Component, OnInit, QueryList, ViewChildren } from '@angular/core'
import { UntypedFormBuilder } from '@angular/forms'

import { debounceTime } from 'rxjs/operators'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

import { TreeNode } from 'primeng/api'
import { TranslatorService } from '@mediacoach-ui-library/global'

import { environment } from '@env'
import { FormMode } from '@core/enums/form-mode.enums'
import { cloneJSON, isNewFormMode } from '@core/utils/main'
import { GenericFormComponent } from '@shared/components/generic-form/generic-form.component'

import { FieldName, GroupName } from './metric-form.enums'
import { VALIDATORS } from '../general-form/general-form.constants'
import { CheckboxGroupComponent } from '../checkbox-group/checkbox-group.component'
import {
  DEPENDENCIES_TREE,
  FORM_INPUTS,
  NAVIGATION_CHECKBOX_GROUP,
  SUPPORTED_TREE,
} from './metric-form.constants'

@UntilDestroy()
@Component({
  selector: 'app-metric-form',
  templateUrl: './metric-form.component.html',
  styleUrls: ['./metric-form.component.scss'],
})
export class MetricFormComponent extends GenericFormComponent implements OnInit {
  @ViewChildren(CheckboxGroupComponent) checkBoxGroupComponents: QueryList<CheckboxGroupComponent>

  selectedTabIndex = 0
  VALIDATORS = VALIDATORS
  navigationCheckBoxGroup = [...NAVIGATION_CHECKBOX_GROUP]
  navigationHeaderCheckBoxData = {
    isShallow: { checked: true },
  }
  dependenciesTree = [...DEPENDENCIES_TREE]
  supportedTree = [...SUPPORTED_TREE]

  isNewFormMode = isNewFormMode

  FieldName = FieldName
  GroupName = GroupName
  FormMode = FormMode

  constructor(
    private fb: UntypedFormBuilder,
    private translatorService: TranslatorService,
  ) {
    super(fb, translatorService, FORM_INPUTS)
  }

  private _getInputOptions() {}

  override buildForm() {
    const detailsFormGroupObj = Object.entries(this.formInputs || {}).reduce(
      (obj, [key, inputInfo]) => ({
        ...obj,
        [key]: [inputInfo.value, inputInfo.validators || []],
      }),
      {},
    )
    this.form = this._fb.group({
      [GroupName.Details]: this._fb.group(detailsFormGroupObj),
      [GroupName.Aggregations]: this._fb.group({}),
      [GroupName.Periods]: this._fb.group({
        [FieldName.IncludePeriods]: this._fb.control(false),
      }),
      [GroupName.Definition]: this._fb.group({
        [FieldName.DefinitionData]: this._fb.control(null),
      }),
    })
    this._defaultData = this.form.value
  }

  override formSubscription() {
    this.bindOnChangeInControls()
    this.form.valueChanges
      .pipe(debounceTime(environment.DEBOUNCE_TIME.LONG), untilDestroyed(this))
      .subscribe((metricForm) => {
        Object.entries(this.formInputs).forEach(([inputKey, inputValue]) => {
          const { selectedField } = inputValue
          if (selectedField) {
            metricForm[inputKey] = (metricForm[inputKey] || {})[selectedField]
          }
        })
        this.onChange.emit(this.form.valid ? cloneJSON(metricForm) : null)
      })
  }

  override ngOnInit() {
    this._getInputOptions()
    this.formSubscription()
  }

  onTabChange(event) {
    this.selectedTabIndex = event.index
  }

  onCheckboxGroupChange(data) {
    // This is intentional
  }

  override resetForm() {
    this._data = {}
    this._defaultData = {}
    this.updateFormValues({ emitEvent: false })
    this.checkBoxGroupComponents.forEach((checkBoxGroup) => checkBoxGroup.resetForm())
  }

  onDependencySelect($event: TreeNode) {
    // This is intentional
  }

  onSupportedSelect($event: TreeNode) {
    // This is intentional
  }
}
