import { AuthPage } from '@core/authentication/auth/enums/auth.enums'
import { environment } from '@env'

export interface SideNavModel {
  name: string
  icon: string
  url: AuthPage
  deactivated: boolean
}

export const NAV_ITEMS: SideNavModel[] = [
  /*{
  name: 'Administration',
  icon: 'user',
  url: AuthPages.Administration,
  deactivated: environment.CURRENT_DEACTIVATED_PAGES[AuthPages.Administration]
}, */ {
    name: 'MTR_TITLE_DASHBOARD',
    icon: 'home',
    url: AuthPage.Dashboard,
    deactivated: environment.CURRENT_DEACTIVATED_PAGES[AuthPage.Dashboard],
  },
  {
    name: 'MTR_TITLE_DATA_INCOMING',
    icon: 'dart-down-squared',
    url: AuthPage.DataIncoming,
    deactivated: environment.CURRENT_DEACTIVATED_PAGES[AuthPage.DataIncoming],
  },
  {
    name: 'MTR_TITLE_DATA_PUBLISHING',
    icon: 'dart-up-squared',
    url: AuthPage.DataPublishing,
    deactivated: environment.CURRENT_DEACTIVATED_PAGES[AuthPage.DataPublishing],
  },
  {
    name: 'MTR_TITLE_MONITORING',
    icon: 'track-changes',
    url: AuthPage.Monitoring,
    deactivated: environment.CURRENT_DEACTIVATED_PAGES[AuthPage.Monitoring],
  },
  {
    name: 'MTR_TITLE_USER_CUSTOMER',
    icon: 'face',
    url: AuthPage.UserCustomerView,
    deactivated: environment.CURRENT_DEACTIVATED_PAGES[AuthPage.UserCustomerView],
  },
  {
    name: 'MTR_TITLE_METRICS_MANAGER',
    icon: 'metadata',
    url: AuthPage.MetricsManager,
    deactivated: environment.CURRENT_DEACTIVATED_PAGES[AuthPage.MetricsManager],
  },
  {
    name: 'MTR_TITLE_PERMISSION_MANAGEMENT',
    icon: 'support',
    url: AuthPage.PermissionManagement,
    deactivated: environment.CURRENT_DEACTIVATED_PAGES[AuthPage.PermissionManagement],
  },
  {
    name: 'MTR_TITLE_MARKETABLE',
    icon: 'comercial',
    url: AuthPage.Marketable,
    deactivated: environment.CURRENT_DEACTIVATED_PAGES[AuthPage.Marketable],
  },
  {
    name: 'MTR_TITLE_STADIUMS',
    icon: 'stadium',
    url: AuthPage.Stadium,
    deactivated: environment.CURRENT_DEACTIVATED_PAGES[AuthPage.Stadium],
  },
  {
    name: 'MTR_TITLE_LICENSES',
    icon: 'license',
    url: AuthPage.License,
    deactivated: environment.CURRENT_DEACTIVATED_PAGES[AuthPage.License],
  },
  {
    name: 'MTR_TITLE_ALERTS',
    icon: 'warning',
    url: AuthPage.Alerts,
    deactivated: environment.CURRENT_DEACTIVATED_PAGES[AuthPage.Alerts],
  },
  {
    name: 'MTR_TITLE_MEDIA_REPORTS',
    icon: 'report',
    url: AuthPage.MediaReports,
    deactivated: environment.CURRENT_DEACTIVATED_PAGES[AuthPage.MediaReports],
  },
  {
    name: 'MTR_TITLE_GRAFANA_WIDGETS',
    icon: 'monitoring',
    url: AuthPage.GrafanaWidgets,
    deactivated: environment.CURRENT_DEACTIVATED_PAGES[AuthPage.GrafanaWidgets],
  },
]
