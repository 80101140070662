import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { LoginComponent } from '@core/authentication/auth/components/login.component'
import { CallbackComponent } from '@core/authentication/auth/components/callback.component'
import { AuthGuard } from '@core/guards/auth.guard'
import { RoleGuard } from '@core/guards/role.guard'
import { AuthPage } from '@core/authentication/auth/enums/auth.enums'
import { HomeModule } from '@features/home/home.module'
import { LogoutComponent } from '@core/authentication/auth/components/logout.component'
import { ErrorPageComponent } from '@core/error/components/error-page/error-page.component'
import { UserCustomersModule } from '@features/user-customers/user-customers.module'
import { StadiumModule } from '@features/stadium/stadium.module'
import { PlaygroundModule } from '@features/playground/playground.module'
import { PermissionManagementModule } from '@features/permission-management/permission-management.module'
import { MonitoringModule } from '@features/monitoring/monitoring.module'
import { MetricsManagerModule } from '@features/metrics-manager/metrics-manager.module'
import { MediaReportsModule } from '@features/media-reports/media-reports.module'
import { MarketableModule } from '@features/marketable/marketable.module'
import { LicenseModule } from '@features/license/license.module'
import { GrafanaModule } from '@features/grafana/grafana.module'
import { DataPublishingModule } from '@features/data-publishing/data-publishing.module'
import { DataIncomingModule } from '@features/data-incoming/data-incoming.module'
import { BoUsersModule } from '@features/bo-users/bo-users.module'
import { AlertsModule } from '@features/alerts/alerts.module'

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'auth', component: CallbackComponent },
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [RoleGuard],
    children: [
      {
        path: AuthPage.Playground,
        loadChildren: () =>
          import('./features/playground/playground.module').then((m) => m.PlaygroundModule),
      },
      {
        path: AuthPage.Dashboard,
        loadChildren: () => import('./features/home/home.module').then((m) => m.HomeModule),
      },
      {
        path: AuthPage.Administration,
        loadChildren: () =>
          import('./features/bo-users/bo-users.module').then((m) => m.BoUsersModule),
      },
      {
        path: AuthPage.Alerts,
        loadChildren: () => import('./features/alerts/alerts.module').then((m) => m.AlertsModule),
      },
      {
        path: AuthPage.UserCustomerView,
        loadChildren: () =>
          import('./features/user-customers/user-customers.module').then(
            (m) => m.UserCustomersModule,
          ),
      },
      {
        path: AuthPage.Marketable,
        loadChildren: () =>
          import('./features/marketable/marketable.module').then((m) => m.MarketableModule),
      },
      {
        path: AuthPage.DataIncoming,
        loadChildren: () =>
          import('./features/data-incoming/data-incoming.module').then((m) => m.DataIncomingModule),
      },
      {
        path: AuthPage.DataPublishing,
        loadChildren: () =>
          import('./features/data-publishing/data-publishing.module').then(
            (m) => m.DataPublishingModule,
          ),
      },
      {
        path: AuthPage.Monitoring,
        loadChildren: () =>
          import('./features/monitoring/monitoring.module').then((m) => m.MonitoringModule),
      },
      {
        path: AuthPage.Stadium,
        loadChildren: () =>
          import('./features/stadium/stadium.module').then((m) => m.StadiumModule),
      },
      {
        path: AuthPage.License,
        loadChildren: () =>
          import('./features/license/license.module').then((m) => m.LicenseModule),
      },
      {
        path: AuthPage.MediaReports,
        loadChildren: () =>
          import('./features/media-reports/media-reports.module').then((m) => m.MediaReportsModule),
      },
      {
        path: AuthPage.GrafanaWidgets,
        loadChildren: () =>
          import('./features/grafana/grafana.module').then((m) => m.GrafanaModule),
      },
      {
        path: AuthPage.PermissionManagement,
        loadChildren: () =>
          import('./features/permission-management/permission-management.module').then(
            (m) => m.PermissionManagementModule,
          ),
      },
      {
        path: AuthPage.MetricsManager,
        loadChildren: () =>
          import('./features/metrics-manager/metrics-manager.module').then(
            (m) => m.MetricsManagerModule,
          ),
      },
      { path: AuthPage.NotPermitted, component: ErrorPageComponent },
    ],
  },
  { path: 'logout', component: LogoutComponent },
  { path: '**', component: ErrorPageComponent },
]

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot(routes, {})],
})
export class AppRoutingModule {}
