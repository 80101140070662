import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { CommonModule } from '@angular/common'
import { TranslateModule } from '@ngx-translate/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

import { ComponentsModule } from '@mediacoach-ui-library/global'

import { SafePipe } from './pipes/safe.pipe'
import { LoadingPipe } from './pipes/loading.pipe'
import {
  BoTemplateDirective,
  CopyToClipboardDirective,
  DefaultImageDirective,
  ExternalLinkDirective,
  OnClickOutsideDirective,
} from './directives'

import { DownloadService } from './services/download/download.service'

import { TreeComponent } from '@shared/components/tree/tree.component'
import { IconComponent } from '@shared/components/icon/icon.component'
import { MenuComponent } from '@shared/components/menu/menu.component'
import { ChipComponent } from '@shared/components/chip/chip.component'
import { ModalComponent } from '@shared/components/modal/modal.component'
import { TableComponent } from '@shared/components/table/table.component'
import { ButtonComponent } from '@shared/components/button/button.component'
import { SearchComponent } from '@shared/components/search/search.component'
import { FiltersComponent } from '@shared/components/filters/filters.component'
import { HeadingComponent } from '@shared/components/heading/heading.component'
import { OverlayComponent } from '@shared/components/overlay/overlay.component'
import { LoadingComponent } from '@shared/components/loading/loading.component'
import { DropdownComponent } from '@shared/components/dropdown/dropdown.component'
import { CalendarComponent } from '@shared/components/calendar/calendar.component'
import { CheckboxComponent } from '@shared/components/checkbox/checkbox.component'
import { MainLogoComponent } from '@shared/components/main-logo/main-logo.component'
import { CountdownComponent } from '@shared/components/countdown/countdown.component'
import { SalesFormComponent } from '@shared/components/sales-form/sales-form.component'
import { QuickViewComponent } from '@shared/components/quick-view/quick-view.component'
import { FileUploadComponent } from '@shared/components/file-upload/file-upload.component'
import { VisionFormComponent } from '@shared/components/vision-form/vision-form.component'
import { MetricFormComponent } from '@shared/components/metric-form/metric-form.component'
import { MorphButtonComponent } from '@shared/components/morph-button/morph-button.component'
import { GeneralFormComponent } from '@shared/components/general-form/general-form.component'
import { AutocompleteComponent } from '@shared/components/autocomplete/autocomplete.component'
import { LabelCounterComponent } from '@shared/components/label-counter/label-counter.component'
import { NotificationsComponent } from '@shared/components/notifications/notifications.component'
import { CheckboxGroupComponent } from '@shared/components/checkbox-group/checkbox-group.component'
import { TableCheckboxComponent } from '@shared/components/table-checkbox/table-checkbox.component'
import { DialogApplicationComponent } from '@shared/components/dialog-application/dialog-application.component'
import { PrimengModule } from '@shared/primeng.module'
import { RoleTranslatePipe } from '@shared/pipes/role-translate.pipe'
import { TimesCircleIcon } from 'primeng/icons/timescircle'
import { TimesIcon } from 'primeng/icons/times'
import { SpinnerIcon } from 'primeng/icons/spinner'
import { ChevronDownIcon } from 'primeng/icons/chevrondown'
import { CheckIcon } from 'primeng/icons/check'
import { RippleModule } from 'primeng/ripple'
import { ApplicationMenuModule } from '@mediacoach/ui'
import { RxFor } from "@rx-angular/template/for";

const PIPES = [SafePipe, RoleTranslatePipe, LoadingPipe]

const DIRECTIVES = [
  BoTemplateDirective,
  ExternalLinkDirective,
  DefaultImageDirective,
  OnClickOutsideDirective,
  CopyToClipboardDirective,
]

const COMPONENTS = [
  IconComponent,
  MenuComponent,
  ChipComponent,
  TableComponent,
  ButtonComponent,
  SearchComponent,
  FiltersComponent,
  HeadingComponent,
  OverlayComponent,
  MainLogoComponent,
  DropdownComponent,
  CalendarComponent,
  CheckboxComponent,
  CheckboxGroupComponent,
  QuickViewComponent,
  SalesFormComponent,
  CountdownComponent,
  FileUploadComponent,
  GeneralFormComponent,
  LabelCounterComponent,
  AutocompleteComponent,
  NotificationsComponent,
  ModalComponent,
  LoadingComponent,
  MorphButtonComponent,
  DialogApplicationComponent,
  VisionFormComponent,
  TreeComponent,
  MetricFormComponent,
  TableCheckboxComponent,
]

const MODULES = [
  CommonModule,
  TranslateModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  ComponentsModule,
  PrimengModule,
  TimesCircleIcon,
  TimesIcon,
  SpinnerIcon,
  ChevronDownIcon,
  CheckIcon,
  RippleModule,
  RxFor,
  ApplicationMenuModule,
]

@NgModule({
  declarations: [...PIPES, ...DIRECTIVES, ...COMPONENTS],
  imports: [...MODULES],
  exports: [...MODULES, ...PIPES, ...DIRECTIVES, ...COMPONENTS],
  providers: [DownloadService],
})
export class SharedModule {}
