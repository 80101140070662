import { FieldName } from './metric-form.enums'
import { InputType } from '../general-form/general-form.enums'
import { FormInputSetting } from '../general-form/general-form.models'
import { GroupItem } from '@shared/components/checkbox-group/checkbox-group.models'
import { TreeNode } from 'primeng/api'

export const FORM_INPUTS: FormInputSetting = {
  [FieldName.Name]: {
    label: 'MTR_COMMON_METRIC_NAME',
    type: InputType.Text,
  },
  [FieldName.FriendlyName]: {
    label: 'MTR_COMMON_METRIC_FRIENDLY_NAME',
    type: InputType.Text,
  },
  [FieldName.Description]: {
    label: 'MTR_COMMON_METRIC_DESCRIPTION',
    type: InputType.Textarea,
  },
  [FieldName.Note]: {
    label: 'MTR_COMMON_METRIC_NOTE',
    type: InputType.Textarea,
  },
  [FieldName.MetricType]: {
    label: 'MTR_COMMON_METRIC_TYPE',
    type: InputType.Dropdown,
  },
  [FieldName.Category]: {
    label: 'MTR_COMMON_METRIC_CATEGORY',
    type: InputType.Dropdown,
  },
}

export const NAVIGATION_CHECKBOX_GROUP: GroupItem[] = [
  {
    id: 'isShallow',
    label: 'MTR_COMMON_SHALLOW',
  },
  {
    id: 'isDeep',
    label: 'MTR_COMMON_DEEP',
  },
]

export const DEPENDENCIES_TREE: TreeNode[] = [
  {
    label: 'TeamGoals',
    data: 'TeamGoals',
    key: 'TeamGoals',
    children: [
      {
        label: 'TeamGoalsFirstHalf',
        data: 'TeamGoalsFirstHalf',
        key: 'TeamGoalsFirstHalf',
        children: [
          {
            label: 'TeamGoalsOppositeFirstHalf',
            data: 'TeamGoalsOppositeFirstHalf',
            key: 'TeamGoalsOppositeFirstHalf',
          },
          {
            label: 'TeamGoalsOwnFirstHalf',
            data: 'TeamGoalsOwnFirstHalf',
            key: 'TeamGoalsOwnFirstHalf',
          },
        ],
      },
      {
        label: 'TeamGoalsSecondHalf',
        data: 'TeamGoalsSecondHalf',
        key: 'TeamGoalsSecondHalf',
        children: [
          {
            label: 'TeamGoalsOppositeSecondHalf',
            data: 'TeamGoalsOppositeSecondHalf',
            key: 'TeamGoalsOppositeSecondHalf',
          },
          {
            label: 'TeamGoalsOwnSecondHalf',
            data: 'TeamGoalsOwnSecondHalf',
            key: 'TeamGoalsOwnSecondHalf',
          },
        ],
      },
    ],
  },
]

export const SUPPORTED_TREE: TreeNode[] = [
  {
    label: 'TeamGoalsSetPlayP',
    data: 'TeamGoalsSetPlayP',
    key: 'TeamGoalsSetPlayP',
    children: [
      { label: 'TeamGoals', data: 'TeamGoals', key: 'TeamGoals' },
      { label: 'TeamGoalsSetPlay', data: 'TeamGoalsSetPlay', key: 'TeamGoalsSetPlay' },
    ],
  },
  {
    label: 'TeamMatchWon',
    data: 'TeamMatchWon',
    key: 'TeamMatchWon',
    children: [
      { label: 'TeamGoals', data: 'TeamGoals', key: 'TeamGoals' },
      { label: 'TeamGoalsAgainst', data: 'TeamGoalsAgainst', key: 'TeamGoalsAgainst' },
    ],
  },
]
