export enum Quality {
  Unknown = 'Unknown',
  Completed = 'Completed',
  Reviewed = 'Reviewed',
}

export enum MatchQualifier {
  Tracking = 'tracking',
  Eventing = 'eventing',
}

export enum MatchValidateField {
  Id = 'id',
  IsFootballMatchValidated = 'isFootballMatchValidated',
  TrackingProvider = 'trackingProvider',
}
